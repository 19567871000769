import { template as template_1e8fb522850340e58799e8a0d115aadb } from "@ember/template-compiler";
const FKText = template_1e8fb522850340e58799e8a0d115aadb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
