import { template as template_38a5d54b728e4e8e9bc4214e80d9b7a2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_38a5d54b728e4e8e9bc4214e80d9b7a2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
